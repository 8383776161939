// Migrated
<template lang="pug">
InView.responsive-image.responsive-hotel-image.w-100.position-relative.z-index-1.overflow-clip.aspect-ratio(
  ref="container"
  :data-loaded="loaded"
  :class="[aspectRatio ? `aspect-ratio__${aspectRatio}` : 'h-100', loaded && '--loaded', `--size-${preferredSize}`]"
  :scroll-target="inViewScrollTarget"
  @trigger="onInView"
)
  .aspect-ratio__content.z-1.position-absolute
    b-skeleton-img.w-100.h-100.cover-image.z-index-1.position-absolute.top-0.responsive-hotel-image__skeleton(
      v-if="loading"
    )
    template(v-else)
      img.responsive-image__thumbnail.position-absolute.responsive-hotel-image__thumbnail.w-100.h-100.z-index-1.top-0.left-0(
        v-if="thumbnail"
        :src="thumbnail"
        :alt="`${alt} (xs)`"
        loading="eager"
        :class="[`object-${thumbFit}`, {'--fade': loaded}]",
      )
      img.w-100.h-100.z-index-10.top-0.left-0.position-absolute.responsive-hotel-image__primary(
        v-if="loaded"
        :src="src"
        :alt="alt"
        loading="lazy",
        :class="`object-${fit}`"
      )
  .position-absolute.z-2.h-100.w-100.top-0.left-0(v-if="hasSlot")
    slot
</template>

<script>

import hotelImagesMixin, { getProvider, supplierURLs } from '@packages/media/mixins/hotel-images.mixin'

export default defineNuxtComponent({
  mixins: [hotelImagesMixin],

  setup () {
    const { public: { images: config } } = useRuntimeConfig()

    return {
      config
    }
  },

  props: {
    alt: {
      type: String,
      required: false
    },

    aspectRatio: {
      type: String,
      default: ''
    },

    /**
     * @prop image: String|Array|Object
     */
    image: {
      type: [String, Array, Object],
      default: null
    },

    /**
     * @prop preferredSize: large|xlarge|og|max|thumbnail|auto
     */
    preferredSize: {
      type: String,
      default: 'auto'
    },

    fit: {
      type: String,
      default: 'cover'
    },

    type: {
      type: String,
      default: 'url'
    },

    sizingRef: {
      type: [Object, htmlTypes.HTMLElement],
      default: null
    },

    thumbFit: {
      type: String,
      default: 'cover'
    },

    /**
     * @prop {Boolean | 'height' | 'width'} clip
     * When true: clip to both width and height
     * When height: set image height to sizingRef/window height, and width to auto
     * When width: same but other way around
     */
    clip: {
      type: [Boolean, String],
      default: true
    },

    inViewScrollTarget: {
      type: htmlTypes.HTMLElement,
      default: undefined
    },

    position: {
      type: String,
      default: 'absolute'
    },
  },

  data () {
    return {
      imageId: null,
      loading: true,
      sizes: [],
      src: '',
      thumbnail: '',
      loaded: false,
      imageData: null,
      inView: false,
      dynamicType: null
    }
  },

  computed: {
    hasSlot () {
      return !!this.$slots?.default
    },

    imageType () {
      return this.dynamicType || this.type || getProvider(this.imageId) || 'url'
    },

    ref () {
      let $ref = document.getElementById('__nuxt')
      const container = this.$refs.container?.$el || this.$refs.container

      if (this.sizingRef?.offsetWidth) {
        $ref = this.sizingRef
      } else if (container?.offsetHeight || container?.offsetWidth) {
        $ref = container
      }

      return $ref
    }
  },

  watch: {
    image () {
      this.updateImage()
    }
  },

  beforeMount () {
    this.updateImage()
  },

  methods: {
    updateImage () {
      if (typeof this.image === 'string') {
        const providerBase = supplierURLs?.[this.type] || supplierURLs.url
        this.imageId = providerBase.replace('[PATH]', this.image)
      } else if (Array.isArray(this.image?.image) || Array.isArray(this.image)) {
        this.imageId = this.image?.image || this.image
        this.dynamicType = this.image?.type
      } else if (this.image?.src && this.image?.type) {
        // case: hotel > carousel
        this.dynamicType = this.image.type
        this.imageId = this.image?.path || this.image?.src || this.image
      }
      this.getThumbnail()
    },

    getThumbnail () {
      const url = this.imageType === 'cloudinary'
        ? this.imageId.replace('[SIZE]', `,${formatCloudinaryThumbSize()}/`)
        : this.imageSize(this.imageId, 'thumbnail')
      preloadImage(url)
        .then(() => {
          this.sizes.push({ src: url, loaded: true, size: 'thumbnail' })
          this.thumbnail = url
          this.loading = false
        })
        .finally(() => {
          if (this.inView) {
            this.prepTotalList()
          }
        })
    },

    prepTotalList () {
      if (this.preferredSize === 'thumbnail') {
        this.finishedLoading(this.thumbnail)
      } else if (this.preferredSize === 'auto') {
        this.autoSizedProviderImage()
      } else if (this.dynamicType === 'cloudinary' || imageIsCloudinary(this.imageId || '')) {
        this.cloudinaryImage()
      } else {
        this.findSizeByList()
      }
    },

    finishedLoading (src) {
      this.src = src
      this.loading = false
      this.loaded = true
    },

    handleImageInList ({ src, size }) {
      return new Promise((resolve, reject) => {
        preloadImage(src)
          .then(() => {
            this.sizes.push({ src, loaded: true, size })
            this.finishedLoading(src)
            resolve()
          })
          .catch(() => (reject(new Error('Could not find image'))))
      })
    },

    findSizeByList () {
      const sizeKeys = ['max', 'og', 'xlarge', 'large']
      const sizes = sizeKeys
        .map(size => ({
          size,
          src: this.imageSize(this.imageId, size)
        }))
      let arrayUniqueByKey = [
        ...new Map(sizes.map(item => [item?.src, item])).values()
      ]
        .sort((a, b) => a?.size === this.preferredSize ? -1 : b?.size === this.preferredSize ? 1 : 0)

      const tryToLoadSize = ({ src, size }) => this.handleImageInList({ size, src })
        .catch((e) => {
          arrayUniqueByKey = arrayUniqueByKey.filter(({ size }) => size !== arrayUniqueByKey[0].size)
          if (arrayUniqueByKey.length > 0) {
            tryToLoadSize(arrayUniqueByKey[0])
          } else {
            this.finishedLoading(this.thumbnail || this.config.fallbackImage)
          }
        })
      tryToLoadSize(arrayUniqueByKey[0])
    },

    autoSizedProviderImage () {
      if (['cloudinary', 'opengds'].includes(this.imageType)) {
        this.cloudinaryImage()
        return
      }

      const size = this.autoImageSize(this.ref, 1, 1.5)
      const src = this.imageSize(this.imageId, size)
      this.handleImageInList({ src, size })
        .catch(this.findSizeByList)
    },

    async cloudinaryImage () {
      const { w, h } = findContainerAwareImageSize(this.ref)

      let src = this.imageId
      if (this.clip) {
        const custom = formatCloudinarySize(
          this.clip === true || this.clip === 'width' ? w : undefined,
          this.clip === true || this.clip === 'height' ? h : undefined,
        )
        src = this.imageId.replace('[SIZE]', `,${custom}/`)
      }

      try {
        return await this.handleImageInList({ src, size: 'auto' })
      } catch (result_1) {
        return this.findSizeByList(result_1)
      }
    },

    onInView () {
      this.inView = true
      if (!this.loading) {
        this.prepTotalList()
      }
    }
  }
})
</script>

<style lang="scss">
/*! purgecss start ignore */
.responsive-image{
  &__thumbnail{
    transition: opacity .5s;
    opacity: 1;
    &.--fade {
      opacity: 0;
    }
  }
  &__link{
    &.btn{
      display: none;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      padding: 0 !important;
      margin: 5px;
    }
  }
  &:hover {
    .responsive-image__link.btn{
      display: block;
    }
  }
}

.responsive-hotel-image {
  --blur: 6px;
  --brightness: 80%;

  &.--size {
    &-thumbnail,
    &-large {
      --blur: 2px;
      --brightness: 88%;
    }
  }

  @keyframes imageIsLoading {
    0%, 100% {
      filter: brightness(95%) blur(var(--blur));
    }

    50% {
      filter: brightness(var(--brightness)) blur(var(--blur));
    }
  }

  .responsive-image__thumbnail {
    filter: brightness(95%) blur(var(--blur));
    animation: imageIsLoading 3s 0.3s linear infinite;
  }
}
/*! purgecss end ignore */
</style>
